import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#61effd',
        main: '#00bcca',
        dark: '#008b99',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffff52',
        main: '#ffd700',
        dark: '#c7a600',
        contrastText: '#000',
      },
    },
  });