import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../theme'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.contentfulBlogPost
  const siteTitle = "Jayla + Artur"
  const { previous, next } = data
  const image = post.featuredImage.gatsbyImageData

  return (
    <ThemeProvider theme={theme} >
      <Layout location={location} title={siteTitle}>
        <Seo
          title={post.title}
          description={post.summary.summary}
        />
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.title}</h1>
            <p>{post.date}</p>
          </header>
          <GatsbyImage image={image} alt="" />
          <section
            dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }}
            itemProp="articleBody"
          />
          <hr />
          <footer>
            <Bio />
          </footer>
        </article>
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`/${previous.slug}`} rel="prev">
                  ← {previous.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/${next.slug}`} rel="next">
                  {next.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    </ThemeProvider>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query pageQuery(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    )
    {
      contentfulBlogPost(id: {eq: $id}) {
        id
        title
        summary {
          summary
        }
        date(formatString: "MMMM DD, YYYY")
        body {
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: FULL_WIDTH
          )
        }
      }
      previous: contentfulBlogPost(id: {eq: $previousPostId}) {
        slug
        title
      }
      next: contentfulBlogPost(id: {eq: $nextPostId}) {
        slug
        title
      }
    }
`

// export const pageQuery = graphql`
//   query BlogPostBySlug(
//     $id: String!
//     $previousPostId: String
//     $nextPostId: String
//   ) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     markdownRemark(id: { eq: $id }) {
//       id
//       excerpt(pruneLength: 160)
//       html
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         description
//       }
//     }
//     previous: markdownRemark(id: { eq: $previousPostId }) {
//       fields {
//         slug
//       }
//       frontmatter {
//         title
//       }
//     }
//     next: markdownRemark(id: { eq: $nextPostId }) {
//       fields {
//         slug
//       }
//       frontmatter {
//         title
//       }
//     }
//   }
// `
